#agent-chat-window {
  height: 100%;
  width: 100%;
  position: fixed;
}

.btn-toggle-video {
  position: absolute;
  right: 158px;
  top: 10rem;
  display: none;
}

.btn-toggle-record {
  position: absolute;
  right: 95.5px;
  top: 10rem;
  display: none;
}

.btn-toggle-stop {
  position: absolute;
  right: 5px;
  top: 10rem;
  display: none;
}

.btn-end-meeting {
  content: "";
  display: block;
  height: 40px;
  width: 40px;
  margin: 0 10px;
  border: none;
  border-radius: 100%;
  background: #1d1c1c;
  transition: all 180ms ease;
}

.btn-stop-meeting {
  position: absolute;
  right: 145px;
  bottom: 51.9%;
  display: none;
}

.agent-view {
  display: none;
  position: absolute;
  flex-direction: row;
  top: 2vh;
  height: 58.5vh;
  vertical-align:middle;  
  border-radius: 0.2rem;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  text-align: center;
  background-color: #272727;
}

.div-help-desk-remote-video {
  margin: 1.5vh 0rem 0rem 2vw;
}

.div-help-desk-local-video{
  margin: 1.5vh 0rem 0rem 2vw;
}

.help-desk-local-video {
  height: 51vh;
  background-color: #101010;
  width: 45vw;
  margin-bottom: 1vh;
}

.help-desk-remote-video {
    height: 51vh;
    background-color: #101010;
    width: 45vw;
    margin-bottom: 1vh;
}



.video-tile--nameplate {
  width: 45vw;
  font-size: 16px;
  font-weight: 600;
  padding-left: 0px;
  padding-bottom: 0.5%;
  text-align: center;
  background: #131212;
  color: #d0d0d0;
  margin-left: auto;
  margin-right: auto;
}

.video-tile--nameplate-local {
    width: 45vw;
    font-size: 16px;
    font-weight: 600;
    padding-left: 0px;
    padding-bottom: 0.5%;
    text-align: center;
    background: #131212;
    color: #d0d0d0;
    margin-left: auto;
    margin-right: auto;
  }

.video-wrapper {
  margin: auto;
  width: auto;
  height: auto;
}

.meeting-controls {
  margin: 0.25rem 1.5rem;
  display: flex;
}


.video-loader {
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 4rem;
  right: 4rem;
  margin: auto;
  border-width: 5px;
  border-color: #2879ff transparent transparent;
  border-radius: 100%;
  animation: rotation .8s infinite linear;
  border-style: solid;
  height: 20px;
  width: 20px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.remote-video-loader {
  top: 22vh;
  right: 50vw;
  display: block;
}

.local-video-loader {
    top: 22vh;
  left: 50vw;
  display: block;
}

.controlBtn {
  content: "";
  display: block;
  height: 40px;
  width: 40px;
  margin: 0 10px;
  border: none;
  border-radius: 100%;
  background: #1d1c1c;
  transition: all 180ms ease;
}

.controlBtn:hover {
  transform: translateY(-4px);
  background-color: #101010;
}

.controlBtn img {
  width: 30px;
}

.alert-agent-end-meeting {
  display: none;
  position: absolute !important;
  margin: 0;
  left: 40%;
  top: 13%;
}
.img1 {
  margin-left: 150%;
  
}
.img2 {
  margin-left: 27%;
  
}