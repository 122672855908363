@import '~antd/dist/antd.css';

.center {
  text-align: center;
}

.site-layout .site-layout-header-background {
	background: #26303B;
	height: 0%;
	
}
.site-layout-background {
	background: white;
	min-height: 100%;
}

.metric-layout {
	/* background: white; */
	height: 15%;
}

.center-content{
	display: flex;
	justify-content: center;
}
.lilly-icon{
	border: 0;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
}
.lilly-icon-logo{
	background-image: url(https://d38fzyjx9jg8fj.cloudfront.net/css/ic_connect.svg);
	background-size: 46px 41px;
	width: 46px;
	height: 41px;
	margin-top: 15%;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #FFFFFF !important; 
	font-weight: 500;
	/* padding-left: 30px; */
 }

 .ant-tabs-tab .ant-tabs-tab-btn {
	color: #FFFFFF !important; 
	/* font-weight: 300; */
	/* padding-left: 30px; */
 }

 .ant-tabs-ink-bar {
	position: absolute;
	background: #FFFFFF !important;
	pointer-events: none;
	/* margin-left: 10px; */
 }

.ant-card .ant-card-head{
	background-color: #4a4a4a;
	color: #FFFFFF;
}
.ant-card .ant-card-body{
	background-color: #F2F2F2;
}
.ant-table-content{
	font-family: "Amazon Ember";
	/* color: #A1A0A1; */
}
.header-title{
	font-family: "Amazon Ember Medium";
	font-size: 24px;
	margin: 10px;
	font-weight: normal;
	color: white;
}

.contact-id{
	font-family: "Amazon Ember Medium";
	font-size: 16px;
	padding-left: 10px;
	padding-right: 10px;
	font-weight: normal;
	color: white;
	background-color: #26303B;
}

.contact-id-authenticated{
	font-family: "Amazon Ember Medium";
	font-size: 16px;
	padding-left: 10px;
	padding-right: 10px;
	font-weight: normal;
	color: black;
	background-color: #2eff1b;
}

.contact-id-red{
	font-family: "Amazon Ember Medium";
	font-size: 16px;
	padding-left: 10px;
	padding-right: 10px;
	font-weight: normal;
	color: black;
	background-color: #ff2525;
}

.contact-id-green{
	font-family: "Amazon Ember Medium";
	font-size: 16px;
	padding-left: 10px;
	padding-right: 10px;
	font-weight: normal;
	color: black;
	background-color: #0efc21;
}

.contact-id-blue{
	font-family: "Amazon Ember Medium";
	font-size: 16px;
	padding-left: 10px;
	padding-right: 10px;
	font-weight: normal;
	color: black;
	background-color: #1b58ff;
}

.contact-id-ir{
	font-family: "Amazon Ember Medium";
	font-size: 16px;
	padding-left: 10px;
	padding-right: 10px;
	font-weight: normal;
	color: black;
	background-color: #fffb1b;
}

.contact-id-purple{
	font-family: "Amazon Ember Medium";
	font-size: 16px;
	padding-left: 10px;
	padding-right: 10px;
	font-weight: normal;
	color: black;
	background-color: #e41bff;
}



.ccpcontainer{
	width: 100%;
    height: 100%;
	min-height: 100%;
	
}

.table-row-light {
	background-color: #FFFFFF;
	
}
.table-row-dark {
    background-color: #e4e4e4;
}